import React from "react";
import PropTypes from "prop-types";
import "./CustomInput.css";
import classNames from "classnames";

const CustomInput = (props) => {
  const {
    type = "text",
    placeholder,
    onChange,
    containerClassNames = "",
    inputClassNames = "",
    onKeyUp = null,
  } = props;

  return (
    <div className={classNames("customInputContainer", containerClassNames)}>
      <input
        className={classNames("customInput", inputClassNames)}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        onKeyUp={onKeyUp}
      />
    </div>
  );
};

CustomInput.propTypes = {
  type: PropTypes.oneOf(["text", "password"]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  containerClassNames: PropTypes.string,
  inputClassNames: PropTypes.string,
};

export default CustomInput;
