import React, { useState } from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import xIcon from "../Assets/Icon/x@2x.png";
import { isCompanyBask } from "../Favorites/utils";

import "./CustomTable.css";

const TitleCell = (props) => {
  const { cell, title, overrides } = props;

  if (overrides[cell] !== undefined) {
    if (typeof overrides[cell] === "function") {
      return <th key={cell}>{overrides[cell]()}</th>;
    } else {
      return <th key={cell}>{overrides[cell]}</th>;
    }
  }

  return <th key={cell}>{title}</th>;
};
TitleCell.propTypes = {
  cell: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  overrides: PropTypes.object,
};

const RowCell = (props) => {
  const { cell, element, overrides, tableType } = props;

  const [showModal, setShowModal] = useState(false);

  const handleShow = () => {
    setShowModal(true);
  };

  const handleHide = () => {
    setShowModal(false);
  };

  const formObjRows = () => {
    const data = element.formObj;
    const mappedKeysGlobal = {
      selfReportedMeds: "Self-reported Meds",
      allergies: "Allergies",
      medicalConditions: "Medical Conditions",
    };
    if (data) {
      const dataKeys = Object.keys(data);
      const regexQ = RegExp(/Q[0-9]+/);
      let qArray = [];
      for (let i = 0; i < dataKeys.length; i++) {
        if (regexQ.test(dataKeys[i])) {
          qArray.push(dataKeys[i]);
        }
      }

      const unmappedDataRows = qArray.map((k) => {
        const num = k.slice(1);
        const questionText = data[`Q${num}`];
        const answerText = data[`A${num}`]
          ? data[`A${num}`].toString()
          : "NO ANSWER PROVIDED";
        const idxPossibles = questionText.search("POSSIBLE ANSWERS: ");
        const formattedA = answerText.split(";");
        const answersList = formattedA.map((answer, idx) => {
          return (
            <li
              key={idx}
              className={
                answer === "NO ANSWER PROVIDED" ? "redBold abnormal" : ""
              }
            >
              <small>{answer}</small>
            </li>
          );
        });
        if (questionText && idxPossibles > 0) {
          const prePossibles = questionText.slice(0, idxPossibles);
          const formattedQ = questionText
            .slice(idxPossibles + 18, questionText.length)
            .split(";");
          const possiblesList = formattedQ.map((possible, idx) => (
            <li key={idx}>
              <small>{possible}</small>
            </li>
          ));
          return (
            <>
              <tr key={`${k}${num}`} className="stackedRow">
                <td>
                  <strong>{prePossibles}</strong>
                  <br />
                  {possiblesList}
                </td>
                <td>
                  <strong>Answer:</strong> {answersList}
                </td>
              </tr>
              <div className="horizontalDivider" />
            </>
          );
        } else {
          return (
            <>
              <tr key={`${k}${num}`} className="stackedRow">
                <td>
                  <strong>{data[`Q${num}`]}</strong>
                </td>
                <td>
                  <strong>Answer:</strong> {answersList}
                </td>
              </tr>
              <div className="horizontalDivider" />
            </>
          );
        }
      });
      const qaTest = RegExp(/[QA][0-9]+/);
      const filtered = Object.keys(data).filter(
        (k) => !qaTest.test(k) && !!mappedKeysGlobal[k]
      );
      const mappedDataRows = filtered.map((key, kIndex) => {
        let formattedA;
        if (data[key] === undefined || data[key] === null) {
          formattedA = "";
        } else if (!!data[key] || !!data[key].toString()) {
          if (Array.isArray(data[key])) {
            formattedA = data[key]
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((pref) => {
                return (
                  <div key={data[key][0].name}>
                    {Object.entries(pref).map((kvArray) => {
                      return (
                        <div key={kvArray[1]}>
                          {kvArray[0]}: {kvArray[1]}
                        </div>
                      );
                    })}
                  </div>
                );
              });
          } else if (typeof data[key] === "object") {
            formattedA = Object.entries(data[key]).map((kvArray) => {
              return `${kvArray[0]}: ${kvArray[1]}`;
            });
          } else {
            formattedA = data[key].toString().split(";");
          }
        } else {
          formattedA = "";
        }

        const answersList =
          formattedA.length > 1
            ? formattedA.map((answer, idx) => (
                <li key={idx}>
                  <small>{answer}</small>
                </li>
              ))
            : formattedA[0];
        return (
          <>
            <tr key={kIndex} className="stackedRow">
              <td>
                <strong>{mappedKeysGlobal[key]}</strong>
              </td>
              <td>
                <strong>Answer:</strong> {answersList}
              </td>
            </tr>
            <div className="horizontalDivider" />
          </>
        );
      });
      return [...mappedDataRows, ...unmappedDataRows];
    }
    return null;
  };

  const justPatientPreference = () => {
    const data = element.formObj;
    const mappedKeysGlobal = {
      patientPreference: "Patient preference",
    };
    if (data) {
      const qaTest = RegExp(/[QA][0-9]+/);
      const filtered = Object.keys(data).filter(
        (k) => !qaTest.test(k) && !!mappedKeysGlobal[k]
      );
      const mappedDataRows = filtered.map((key, kIndex) => {
        let formattedA;
        if (data[key] === undefined || data[key] === null) {
          formattedA = "";
        } else if (!!data[key] || !!data[key].toString()) {
          if (Array.isArray(data[key])) {
            formattedA = data[key]
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((pref) => {
                return (
                  <div key={data[key][0].name}>
                    {Object.entries(pref).map((kvArray) => {
                      return (
                        <div key={kvArray[1]}>
                          {kvArray[0]}: {kvArray[1]}
                        </div>
                      );
                    })}
                  </div>
                );
              });
          } else if (typeof data[key] === "object") {
            formattedA = Object.entries(data[key]).map((kvArray) => {
              return `${kvArray[0]}: ${kvArray[1]}`;
            });
          } else {
            formattedA = data[key].toString().split(";");
          }
        } else {
          formattedA = "";
        }

        const answersList =
          formattedA.length > 1
            ? formattedA.map((answer, idx) => (
                <li key={idx}>
                  <small>{answer}</small>
                </li>
              ))
            : formattedA[0];
        return (
          <tr key={kIndex}>
            <td>{answersList}</td>
          </tr>
        );
      });
      return mappedDataRows;
    }
    return null;
  };

  const rxHistory =
    tableType === "visits" && element.rxHistory?.length
      ? element.rxHistory.map((rxObj) => {
          return (
            <tr>
              <div>
                <strong>Product name:</strong> {rxObj.favoriteName}
              </div>
              <div>
                <strong>Medication:</strong> {rxObj.name}
              </div>
              <div>
                <strong>Refills:</strong> {rxObj.refills}
              </div>
              <div>
                <strong>Quantity:</strong> {rxObj.quantity}
              </div>
              <div>
                <strong>MedId:</strong>{" "}
                {/^bask/.test(element.company)
                  ? rxObj.baskName
                  : rxObj.clientName}
              </div>
              <div>
                <strong>Rx Timestamp:</strong> {rxObj.rxTimestamp}
              </div>
              <div className="horizontalDivider" />
            </tr>
          );
        })
      : "None";

  if (overrides[cell] !== undefined) {
    if (typeof overrides[cell] === "function") {
      return <td key={cell}>{overrides[cell](element[cell], element)}</td>;
    } else {
      return <td key={cell}>{element[cell]}</td>;
    }
  }

  if (cell === "masterId") {
    return (
      <td key={cell}>
        <button onClick={handleShow} className="linkButton">
          {element[cell]}
        </button>

        <Modal
          show={showModal}
          onHide={handleHide}
          dialogClassName="visitModalDims"
        >
          <div className="modalHeader">
            <div className="modalTitle">
              <span>Visit Details</span>
              <button onClick={handleHide} className="modalCloseButton">
                <img src={xIcon} className="xIcon" alt="close" />
              </button>
            </div>
          </div>
          <div className="modalBody">
            <div className="splitModalBody">
              <div className="leftColumn">
                <div className="sectionHeader">Questionnaire Data:</div>
                <table>
                  <tbody>{formObjRows()}</tbody>
                </table>
              </div>
              <div className="rightColumn">
                <div className="topRight">
                  <div className="sectionHeader">Patient Preference:</div>
                  <table>
                    <tbody>{justPatientPreference()}</tbody>
                  </table>
                </div>
                <div className="bottomRight">
                  <div className="sectionHeader">RX History:</div>
                  <table>
                    <tbody>{rxHistory}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </td>
    );
  }

  return <td key={cell}>{element[cell]}</td>;
};
RowCell.propTypes = {
  cell: PropTypes.string.isRequired,
  element: PropTypes.object.isRequired,
  overrides: PropTypes.object,
};

const CustomTable = (props) => {
  const {
    list = [],
    getRowKey,
    tableKeyTitles = {},
    titleOverrides = {},
    rowOverrides = {},
    tableType,
    companyName,
  } = props;

  const tableKeys = isCompanyBask(companyName)
    ? Object.keys(tableKeyTitles).filter((k) => k !== "clientName")
    : Object.keys(tableKeyTitles);
  const tableKeyEntries = isCompanyBask(companyName)
    ? Object.entries(tableKeyTitles).filter((k) => k[0] !== "clientName")
    : Object.entries(tableKeyTitles);

  return (
    <Table responsive hover striped>
      <thead>
        <tr>
          {tableKeyEntries.map(([cell, title], i) => (
            // {Object.entries(tableKeyTitles).map(([cell, title], i) => (
            <TitleCell
              key={i}
              cell={cell}
              title={title}
              overrides={titleOverrides}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {list.map((element) => {
          // TODO add virtualization or pagination

          return (
            <tr key={getRowKey(element)} className={element.className}>
              {tableKeys.map((cell, i) => (
                <RowCell
                  key={i}
                  cell={cell}
                  element={element}
                  overrides={rowOverrides}
                  tableType={tableType}
                />
              ))}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
CustomTable.propTypes = {
  list: PropTypes.array.isRequired,
  getRowKey: PropTypes.func.isRequired,
  tableKeyTitles: PropTypes.object.isRequired,
  titleOverrides: PropTypes.object,
  rowOverrides: PropTypes.object,
};

export default CustomTable;
