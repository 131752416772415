import React from "react";
import { Form } from "react-bootstrap";
import classnames from "classnames";

import "./FormInputs.css";

const FormInputs = (props) => {
  const { config, formData, compareTo = {}, onChange, errors } = props;

  const handleChange = (e) => {
    const { value, name, type } = e.target;
    const newFormData = { ...formData };

    const configInput = config.flat().find((input) => input.name === name);

    let newValue;
    switch (type) {
      case "number":
        newValue = Number(value) ?? 0;
        break;
      case "checkbox": {
        const previousValue = formData[name];
        if (value === "All") {
          const optionsSet = new Set(configInput.options);
          const prevValue = formData[name] ?? configInput.options;

          const lockedValues = configInput.locked ?? [];
          lockedValues.forEach((locked) => {
            if (prevValue.includes(locked)) {
              optionsSet.add(locked);
            } else {
              optionsSet.delete(locked);
            }
          });

          newValue = Array.from(optionsSet);
        } else if (value === "None") {
          const optionsSet = new Set();
          const prevValue = formData[name] ?? configInput.options;

          const lockedValues = configInput.locked ?? [];
          lockedValues.forEach((locked) => {
            if (prevValue.includes(locked)) {
              optionsSet.add(locked);
            } else {
              optionsSet.delete(locked);
            }
          });

          newValue = Array.from(optionsSet);
        } else {
          const formDataIndex = previousValue && previousValue.indexOf(value);
          if (previousValue && previousValue.includes(value)) {
            newValue = [...previousValue];
            newValue.splice(formDataIndex, 1);
          } else {
            newValue = previousValue ? [...previousValue, value] : [value];
          }
        }
        break;
      }
      default:
        newValue = value;
    }

    // don't change input if new value is not pass validation
    if (configInput.validation && !configInput.validation(newValue)) {
      return;
    }

    newFormData[name] = newValue;
    onChange(newFormData);
  };

  const formInput = (input) => {
    switch (input.type) {
      case "select": {
        const { name, options, locked } = input;
        return (
          <Form.Select
            name={name}
            value={formData[name]}
            onChange={handleChange}
            className={"formInput py-0"}
            disabled={locked}
          >
            <option value="" />
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label ?? option.value}
              </option>
            ))}
          </Form.Select>
        );
      }

      case "creatableSelect": {
        const { name, text, placeholder, options, locked } = input;
        return (
          <>
            <input
              name={name}
              list={name}
              placeholder={placeholder || text}
              onChange={handleChange}
              type="text"
              className={"formInput py-0"}
              value={formData[name] || ""}
              disabled={locked}
            />
            <datalist id={name}>
              {options.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label ?? value}
                </option>
              ))}
            </datalist>
          </>
        );
      }

      case "checkbox": {
        const { name, options, locked = [] } = input;
        const checkedCompanies = options.sort().map((companyName) => {
          const isChecked = formData.company.includes(companyName);
          return (
            <div className="checkGroup" key={companyName}>
              <input
                type="checkbox"
                value={companyName}
                name={name}
                onChange={handleChange}
                checked={isChecked}
                className="checkBox"
                disabled={locked.includes(companyName)}
              />
              <span>{companyName}</span>
            </div>
          );
        });
        return (
          <div className="scrolling checkedStates">
            <div className="checkGroup">
              <input
                type="checkbox"
                value="All"
                name={name}
                onChange={handleChange}
                checked={formData.company.length === options.length}
                className="checkBox"
              />
              <span>All</span>
            </div>
            <div className="checkGroup">
              <input
                type="checkbox"
                value="None"
                name={name}
                onChange={handleChange}
                checked={formData.company.length === 0}
                className="checkBox"
              />
              <span>None</span>
            </div>

            {checkedCompanies}
          </div>
        );
      }

      default: {
        const { name, text, required, placeholder, type, locked } = input;

        let value;
        if (type === "number") {
          if (
            !required &&
            !formData[name] &&
            typeof formData[name] !== "number"
          ) {
            value = "";
          } else {
            value = Number(formData[name] || "");
          }
        } else {
          value = formData[name] || "";
        }
        return (
          <input
            name={name}
            placeholder={placeholder || text}
            onChange={handleChange}
            type={type || "text"}
            className="formInput"
            value={value}
            min={0}
            disabled={locked}
            step="any"
          />
        );
      }
    }
  };

  const formRow = (row, index) => (
    <div className="formRow" key={index}>
      {row.map((input, index) => {
        const { name, text, required } = input;
        const haveToCompare =
          compareTo[name] !== undefined && compareTo[name] !== "";
        const inputEqualPending =
          haveToCompare &&
          !!formData[name]?.toString() &&
          compareTo[name].toString() === formData[name].toString();
        const compareToClass = classnames(
          "formInput-compareTo",
          inputEqualPending
            ? "formInput-compareEqual"
            : "formInput-compareNotEqual"
        );

        const foundError = errors
          .find((error) => error.key === name)
          ?.message.split(",")[0];

        return (
          <div key={index}>
            <div className="formField">{`${text}${required ? "*" : ""}`}</div>
            {!!foundError && (
              <div className="formField errorText">{foundError}</div>
            )}
            {formInput(input)}
            {haveToCompare && (
              <div className="formField">
                Update:
                <span className={compareToClass}>
                  {compareTo[name].toString()}
                </span>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );

  return config.map(formRow);
};

export default FormInputs;
