import React from "react";
import PropTypes from "prop-types";
import CustomTable from "../Components/CustomTable";
import { tableKeyTitles } from "./utils";

const PendingFavoritesTable = (props) => {
  const { favorites = [], acceptReject = () => {}, user } = props;

  const titleOverrides = {
    createUpdate: "Status",
  };

  const rowOverrides = {
    createUpdate: (_, favorite) => {
      if (favorite.className === "isRejected") {
        return (
          <div>
            <div>Rejected</div>
            <button
              onClick={acceptReject}
              name={favorite.clientName}
              className="acceptButton"
            >
              OK
            </button>
          </div>
        );
      } else {
        return "Pending";
      }
    },
  };

  return (
    <div className="favorites-page">
      <CustomTable
        list={favorites}
        getRowKey={(element) => element._id}
        tableKeyTitles={tableKeyTitles}
        titleOverrides={titleOverrides}
        rowOverrides={rowOverrides}
        companyName={user.companyName}
      />
    </div>
  );
};
PendingFavoritesTable.propTypes = {
  favorites: PropTypes.array.isRequired,
  acceptReject: PropTypes.func,
};

export default PendingFavoritesTable;
