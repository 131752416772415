import React from "react";
import PropTypes from "prop-types";
import { acceptRejection } from "../utils/router";
import PendingFavoritesTable from "./PendingFavoritesTable";
// import CustomInput from "../Components/CustomInput";
// import { sortFavorites } from "./utils";
import "./Favorites.css";

const PendingFavorites = (props) => {
  const {
    user,
    pendingFavorites,
    rejectedFavorites,
    handleFetch,
    error,
    setError,
  } = props;

  // const [medIdFilter, setMedIdFilter] = useState("");

  const handleAcceptRejection = async (e) => {
    const { name } = e.target;
    const response = await acceptRejection(user.token, name, setError);
    if (response?.error) {
      setError("Error accepting rejection");
    }
    await handleFetch();
  };

  //   const handleMedIdFilterChange = (e) => {
  //     setMedIdFilter(e.target.value);
  //   };

  const combinedList = [
    ...pendingFavorites,
    ...rejectedFavorites.map((fav) => {
      return {
        ...fav,
        className: "isRejected",
      };
    }),
  ];

  // const favoriteList = sortFavorites(combinedList).filter((fav) => {
  //   if (!medIdFilter) return true;
  //   return fav.medId.includes(medIdFilter);
  // });

  return (
    <div className="favorites-page">
      {error === "" ? (
        <>
          {/* <CustomInput
            type="text"
            name="medId"
            placeholder="Search by Medication ID"
            onChange={handleMedIdFilterChange}
          /> */}
          <PendingFavoritesTable
            favorites={combinedList}
            // favorites={favoriteList}
            acceptReject={handleAcceptRejection}
            user={user}
          />
        </>
      ) : (
        <div className="error-container">
          <h1>{error}</h1>
        </div>
      )}
    </div>
  );
};

PendingFavorites.propTypes = {
  user: PropTypes.object.isRequired,
};

export default PendingFavorites;
