export const sortVisits = (visits) => {
  return visits;
};

export const tableKeyTitles = {
  masterId: "MasterID",
  _id: "VisitID",
  visitType: "Visit type",
  createTimestamp: "Created date",
  updateTimestamp: "Last updated date",
  status: "Status",
  outcome: "Outcome",
  resolvedTimestamp: "Resolved date",
  company: "Company",
};
