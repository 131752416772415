/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

import Login from "./Login/Login";
import Dashboard from "./Dashboard/Dashboard";
import "./App.css";

function App() {
  const [user, setUser] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!loggedIn) {
  //     localStorage.removeItem("apiKey");
  //     localStorage.removeItem("apiKeyDashboard");
  //     navigate("/");
  //   }
  //   else if (apiKeyDashboard) {
  //     setLoggedIn(true);
  //     setUser({ apiKeyDashboard });
  //     navigate("/dashboard");
  //   } else {
  //     setLoggedIn(false);
  //     localStorage.removeItem("apiKeyDashboard");
  //     setUser({});
  //     navigate("/");
  //   }
  // }, [apiKeyDashboard, navigate]);

  useEffect(() => {
    setLoggedIn(false);
    localStorage.removeItem("dashboardToken");
    setUser({});
    navigate("/");
  }, []);

  const setUserInfo = async (userInfo) => {
    setUser(userInfo);
    setLoggedIn(true);
    navigate("/dashboard");
  };

  const handleLogout = async () => {
    localStorage.removeItem("dashboardToken");
    setLoggedIn(false);
    setUser({});
    navigate("/");
  };

  const handleOnIdle = async () => {
    if (loggedIn) await handleLogout();
  };

  const DEFAULT_EVENTS = [
    "keydown",
    "wheel",
    "DOMMouseScroll",
    "mouseWheel",
    "mousedown",
    "touchstart",
    "touchmove",
    "MSPointerDown",
    "MSPointerMove",
    "visibilitychange",
  ];

  // check for idle user every 15 minutes
  useIdleTimer({
    // timeout: 15,
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    debounce: 500,
    startOnMount: false,
    events: DEFAULT_EVENTS,
  });

  return (
    <div className="App">
      <Routes>
        {!loggedIn ? (
          <Route path="/" element={<Login setUserInfo={setUserInfo} />} />
        ) : (
          <>
            <Route path="/dashboard" element={<Dashboard user={user} />} />
          </>
        )}
      </Routes>
    </div>
  );
}

export default App;
