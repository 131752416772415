import React from "react";
import PropTypes from "prop-types";
import FavoriteModalFormButton from "./FavoriteModalFormButton";
import { addOrEditFavorite } from "../../utils/router";

const PendingFavoriteUpdate = (props) => {
  const { user, favorite, visitTypes, onSuccess, pharmacies } = props;

  const handleFormSubmit = async (formData, setErrorMessage) => {
    const { token } = user;
    const res = await addOrEditFavorite(
      token,
      user.companyName,
      formData,
      setErrorMessage
    );
    if (res) {
      return res;
    }
    return false;
  };

  return (
    <FavoriteModalFormButton
      btnTitle="Edit"
      modalTitle="Update Details"
      defaultFormData={favorite}
      onFormSubmit={handleFormSubmit}
      onSuccess={onSuccess}
      visitTypes={visitTypes}
      pharmacies={pharmacies}
      mode="edit"
      company={user.companyName}
    />
  );
};

PendingFavoriteUpdate.propTypes = {
  user: PropTypes.object.isRequired,
  favorite: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  visitTypes: PropTypes.array.isRequired,
};

export default PendingFavoriteUpdate;
